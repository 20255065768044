<template>
  <v-row
    class="align-right"
    justify="center"
  >
    <v-col
      class="pa-4"
      cols="12"
      lg="4"
      md="6"
      sm="8"
      xs="6"
    >
      <div v-if="!emailSent">
        <h2>Odśwież hasło</h2>
        <p class="mb-10">Proszę wpisać swój e-mail. Wyślemy link do odświeżenia hasła.</p>
      </div>
      <div v-if="emailSent">
        <h2>Link został wysłany</h2>
        <p class="mb-10">Sprawdź swoją skrzynkę odbiorczą. Możesz zamknąć kartę.</p>
      </div>
      <v-form
        v-model="isFormValid"
        @submit.prevent="remindPassword"
      >
        <v-text-field
          v-if="!emailSent"
          v-model="email"
          :autofocus="true"
          :rules="emailRules"
          label="Twój e-mail"
          required
        ></v-text-field>
        <v-row
          class="align-right"
          justify="center"
        >
          <v-col cols="6"></v-col>
          <v-col
            class="text-right"
            cols="6"
          >
            <v-btn
              :to="{name: 'Login'}"
              link
              small
              text
            >
              Wróć do logowania
            </v-btn>
          </v-col>
        </v-row>
        <v-btn
          v-if="!emailSent"
          :loading="loading"
          block
          class="mt-4"
          color="primary"
          large
          type="submit"
        >
          Odśwież hasło
        </v-btn>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import {email, required} from "@/utils/validationRules";
import {remindPassword} from "@/services/authService";
import {notify} from "@/services/notificationService";

export default {
  name: 'RemindPassword',
  data: () => ({
    email: '',
    emailRules: [required, email],

    emailSent: false,
    loading: false,
    isFormValid: false
  }),
  methods: {
    async remindPassword() {
      if (this.isFormValid) {
        try {
          this.loading = true
          await remindPassword(this.email)
          this.emailSent = true
          notify('success', 'Link do resetu hasła został wysłany')
        } catch (e) {
          notify('error', 'Nie udało się wysłać linku aktywacyjnego', 'Sparawdź poprawność adresu email')
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
